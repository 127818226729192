import React from 'react';
import Idea from '../../../../assets/idea.png'
import Wireframe from '../../../../assets/wireframe.png'
import FinalDesign from '../../../../assets/final-design.png'
import Coding from '../../../../assets/coding.png'
import Testing from '../../../../assets/testing.png'
import Optimization from '../../../../assets/optimization.png'
import styles from './Procedure.module.css';
import { useTranslation } from 'react-i18next';
import ProcedureImageLeft from './ProcedureImageLeft/ProcedureImageLeft';
import ProcedureImageRight from './ProcedureImageRight/ProcedureImageRight';
import DownArrow from './DownArrow/DownArrow';

const procedures = [
    {
        title: "mobile-apps-procedure-idea-title",
        description: "mobile-apps-procedure-idea-paragraph",
        image: Idea
    },
    {
        title: "mobile-apps-procedure-drafts-title",
        description: "mobile-apps-procedure-drafts-paragraph",
        image: Wireframe
    },
    {
        title: "mobile-apps-procedure-final-design-title",
        description: "mobile-apps-procedure-final-design-paragraph",
        image: FinalDesign
    },
    {
        title: "mobile-apps-procedure-app-implementation-title",
        description: "mobile-apps-procedure-app-implementation-paragraph",
        image: Coding
    },
    {
        title: "mobile-apps-procedure-testing-title",
        description: "mobile-apps-procedure-testing-paragraph",
        image: Testing
    },
    {
        title: "mobile-apps-procedure-optimization-title",
        description: "mobile-apps-procedure-optimization-paragraph",
        image: Optimization
    }
];

function Procedure() {
    const { t } = useTranslation();
    return (
        <div className={styles.procedure_container}>
            <h1>{t('mobile-apps-procedure-title')}</h1>
            <ProcedureImageLeft procedure={procedures[0]} />
            <DownArrow />
            <ProcedureImageRight procedure={procedures[1]} />
            <DownArrow />
            <ProcedureImageLeft procedure={procedures[2]} />
            <DownArrow />
            <ProcedureImageRight procedure={procedures[3]} />
            <DownArrow />
            <ProcedureImageLeft procedure={procedures[4]} />
            <DownArrow />
            <ProcedureImageRight procedure={procedures[5]} />
        </div>
    )
}

export default Procedure