import React from 'react';
import { useTranslation } from 'react-i18next';
import Maintenance from '../../../../assets/maintenance.png'
import Updates from '../../../../assets/updates.png'
import styles from './AfterRelease.module.css';

function AfterRelease() {
    const { t } = useTranslation();
    return (
        <div className={styles.after_release_container}>
            <h1>{t('mobile-apps-after-release-title')}</h1>
            <p>{t('mobile-apps-after-release-paragraph')}</p>
            <div className={styles.after_release_row}>
                <div className={styles.after_release_row_card}>
                    <div className={styles.after_release_title_and_image}>
                        <img src={Maintenance} alt='Maintenance' />
                        <h2>{t('mobile-apps-after-release-maintenance-title')}</h2>
                    </div>
                    <p>{t('mobile-apps-after-release-maintenance-paragraph')}</p>
                </div>
                <div className={styles.after_release_row_card}>
                    <div className={styles.after_release_title_and_image}>
                        <img src={Maintenance} alt='Maintenance' />
                        <h2>{t('mobile-apps-after-release-updates-title')}</h2>
                    </div>
                    <p>{t('mobile-apps-after-release-updates-paragraph')}</p>
                </div>
            </div>
        </div>
    )
}

export default AfterRelease