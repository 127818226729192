import React from 'react'
import NotFound from '../../assets/404.png';
import styles from './Page404.module.css';

function Page404() {
  return (
    <div className={styles.page_404_container}>
      <img src={NotFound} alt="not-found"/>
    </div>
  )
}

export default Page404