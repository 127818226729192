import './App.css';
import Routing from './Navigation/routing';

function App() {
  return (
    <Routing />
  );
}

export default App;
