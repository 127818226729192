import React from 'react';
import styles from './HomepageServices.module.css';
import HomepageServicesCard from './HomepageServicesCard/HomepageServicesCard';
import { useTranslation } from 'react-i18next';
import { services } from '../../../utils/constants';



function HomepageServices() {
    const { t } = useTranslation();
    return (
        <div className={styles.homepage_services_container}>
            <h1>{t('homepage-services-section-title')}</h1>
            <div className={styles.homepage_services_row}>
                <div className={styles.homepage_services_col}>
                    <HomepageServicesCard
                        title={services[1].title}
                        description={services[1].description}
                        image={services[1].image}
                        link={services[1].link}
                    />
                </div>
                <div className={styles.homepage_services_col}>
                    <HomepageServicesCard
                        title={services[0].title}
                        description={services[0].description}
                        image={services[0].image}
                        link={services[0].link}
                    />
                    <HomepageServicesCard
                        title={services[2].title}
                        description={services[2].description}
                        image={services[2].image}
                        link={services[2].link}
                    />
                </div>
                <div className={styles.homepage_services_col}>
                    <HomepageServicesCard
                        title={services[3].title}
                        description={services[3].description}
                        image={services[3].image}
                        link={services[3].link}
                    />
                </div>
            </div>

        </div>
    )
}

export default HomepageServices