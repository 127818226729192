import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import eShop from '../../../../assets/eshop.png';
import styles from './YourChoice.module.css';

function YourChoice() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function goToContact(){
        navigate('/contact');
    }

    return (
        <div className={styles.your_choice_container}>
            <div className={styles.your_choice_container_row}>
                <div className={styles.your_choice_container_row_image}>
                    <img src={eShop} alt='eShop' />
                </div>
                <div className={styles.your_choice_container_row_body}>
                    <h2>{t('ecommerce-page-choice-section-title')}</h2>
                    <p>
                        {t('ecommerce-page-choice-section-paragraph01')}
                        <br /><br />
                        {t('ecommerce-page-choice-section-paragraph02')}
                        <br /><br />
                        {t('ecommerce-page-choice-section-paragraph03')}
                    </p>
                    <button className='main-btn' onClick={goToContact}>{t('contact-us-btn')}</button>
                </div>
            </div>
        </div>
    )
}

export default YourChoice