import React from 'react'
import { CustomFooter, CustomNavbar } from '../../Components'
import HomepageHeader from './HomepageHeader/HomepageHeader'
import HomepagePartners from './HomepagePartners/HomepagePartners'
import HomepageServices from './HomepageServices/HomepageServices'
import HomepageValues from './HomepageValues/HomepageValues'

function Homepage() {
  return (
    <div>
      <CustomNavbar />
      <HomepageHeader />
      <HomepageServices />
      <HomepagePartners />
      <HomepageValues />
      <CustomFooter />
    </div>
  )
}

export default Homepage