import React from 'react';
import { useTranslation } from 'react-i18next';
import { values } from '../../../utils/constants';
import styles from './HomepageValues.module.css';

function HomepageValues() {
    const { t } = useTranslation();
    return (
        <div className={styles.homepage_values_container}>
            {
                values.map((value) => {
                    return <div className={styles.homepage_values_single_value}>
                        <div className={styles.homepage_values_single_value_image}>
                            <img src={value.image} alt={value.title} />
                        </div>
                        <div className={styles.homepage_values_single_value_body}>
                            <h3>{t(`${value.title}`)}</h3>
                            <p>{t(`${value.body}`)}</p>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default HomepageValues