import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import './ContactUsForm.css'


const ContactUsForm = () => {

    var captchaValue = "";
    const { t } = useTranslation();

    function onChange(value) {
        captchaValue = value;
        // console.log('Captcha value:', captchaValue);
        if (  captchaValue === "" || captchaValue == null){
            document.getElementById('recaptcha-verification-text').style.display = "block";
        } else {
            document.getElementById('recaptcha-verification-text').style.display = "none";
        }
      }

    const form = useRef();

    const SendEmail = (e) => {
        e.preventDefault();

        if ( document.getElementById('username').value === "" ||
                document.getElementById('email').value === "" ||
                document.getElementById('subject').value === "" ||
                document.getElementById('message').value === ""
        ){
            document.getElementById('fill-fields-verification-text').style.display = "block";
        } else {
            document.getElementById('fill-fields-verification-text').style.display = "none";
            if (  captchaValue !== "" && captchaValue != null){
                emailjs.sendForm('service_2s5pkzv', 'template_qbm1rd9', form.current, 'user_sDXJ3HsWYS2kXgCxQB5PB')
                .then((result) => {
                    console.log(result.text);
                    document.getElementById('valid-email-verification-text').style.display = "none";
                    document.getElementById('invalid-recaptcha').style.display = "none";
                    document.getElementById('email-sent').style.display = "block";
                    document.getElementById('username').value = "";
                    document.getElementById('email').value = "";
                    document.getElementById('subject').value = "";
                    document.getElementById('message').value = "";
                }, (error) => {
                    console.log(error.text);
                    if ( error.text.includes("501")){
                        document.getElementById('valid-email-verification-text').style.display = "block";
                    } else if ( error.text.includes("timeout") ){
                        document.getElementById('invalid-recaptcha').style.display = "block";
                    }
                });
            } else {
                console.log("recaptcha needed");
                document.getElementById('recaptcha-verification-text').style.display = "block";
            }
        }

        

        
    };

    return (
        <div className="contact-us-container">
            <div className="contact-us-row">
                <h1>{t('contact-us-form-title')}</h1>
                <form className="contact-us-form" ref={form} onSubmit={SendEmail}>
                    <div className="form-name">
                        <h3>{t('contact-us-form-name')}</h3>
                        <input
                                type="text"
                                name="name"
                                className="username"
                                placeholder='Name'
                                id='username'
                                tabIndex="1"
                        />
                    </div>
                    <div className="form-email">
                        <h3>{t('contact-us-form-email')}</h3>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="email"
                            placeholder="example@corp.com"
                            tabIndex="3"
                        />
                    </div>
                    <div className="form-subject">
                        <h3>{t('contact-us-form-subject')}</h3>
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            className="subject"
                        />
                    </div>
                    <div className="form-message">
                        <h3>{t('contact-us-form-message')}</h3>
                        <textarea
                            placeholder="Start typing..."
                            className="message"
                            name="message"
                            id='message'
                        />
                    </div>
                    <div className="form-send-button">
                        <button type="button" onClick={SendEmail}>{t('contact-us-form-btn')}</button>
                    </div>
                    <div className='recaptcha'>
                        <ReCAPTCHA
                            sitekey="6LeIo5YdAAAAAKvYfwqROeBFNtxEmOoMNtzL9P-R"
                            onChange={onChange}
                            className="recaptcha-box"
                        />
                        <p id='recaptcha-verification-text'>
                            Complete reCAPTCHA verification.
                        </p>
                        <p id='valid-email-verification-text'>
                            Please enter a valid email.
                        </p>
                        <p id='fill-fields-verification-text'>
                            Please fill all of the form's fields.
                        </p>
                        <p id='invalid-recaptcha'>
                            Invalid reCAPTCHA. Please reload your page.
                        </p>
                        <p id='email-sent'>
                            Your email was sent successfully.
                        </p>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default ContactUsForm
