import React, { createRef } from 'react';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import { partners } from '../../../../utils/constants';
import styles from './HomepagePartnersCarousel.module.css';
import HomepagePartnersCard from '../HomepagePartnersCard/HomepagePartnersCard';


function HomepagePartnersCarousel() {
    let carouselRef = createRef();

    return (
        <div className={styles.homepage_partners_container}>
            <Carousel autoplay ref={carouselRef}>
                {
                    partners.map((partner) => {
                        return <div>
                            <HomepagePartnersCard partner={partner} />
                        </div>
                    })
                }
            </Carousel>
            <div className={styles.partners_select_cards_row}>
                {
                    partners.map((partner, index) => {
                        return <div className={styles.partners_select_cards_single_card}
                        onClick={() => {
                            carouselRef.current.goTo(index, false);
                        }}
                        >
                            <img src={partner.image} alt={partner.title} />
                            <h3>{partner.title}</h3>
                        </div>
                    })
                }
            </div>
        </div>

    )
}

export default HomepagePartnersCarousel