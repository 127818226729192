import React from 'react';
import { ContactUsForm, CustomFooter, CustomNavbar } from '../../Components';

function Contact() {
  return (
    <div>
        <CustomNavbar />
        <ContactUsForm />
        <CustomFooter />
    </div>
  )
}

export default Contact