import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';
import Loading from './Components/Loading/Loading';


ReactDOM.render(
  <Suspense fallback={Loading}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);