import React from 'react';
import { useTranslation } from 'react-i18next';
import LiF from '../../../assets/LiF.png';
import styles from './AboutUsHeader.module.css';

function AboutUsHeader() {
    const {t} = useTranslation();

    return (
        <div className={styles.about_us_header_container}>
            <img src={LiF} alt='Logic In Flow' />
            <h2>{t('about-us-page-header-title')}</h2>
            <p>{t('about-us-page-header-paragraph01')}</p>
            <br/>
            <p>{t('about-us-page-header-paragraph02')}</p>
        </div>
    )
}

export default AboutUsHeader