import React from 'react';
import styles from './DownArrow.module.css';
import Down from '../../../../../assets/down-arrow.png'

import { motion } from "framer-motion";

const logoVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 150,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
        }
    }
};

function DownArrow() {
    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            <motion.div variants={logoVariants}>
                <div className={styles.down_arrow_container}>
                    <img src={Down} alt="Down Arrow" />
                </div>
            </motion.div>
        </motion.div>
    )
}

export default DownArrow