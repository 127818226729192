import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomepagePartners.module.css';
import HomepagePartnersCarousel from './HomepagePartnersCarousel/HomepagePartnersCarousel';

function HomepagePartners() {

    const { t } = useTranslation();
    return (
        <div className={styles.homepage_partners_container}>
            <h1>{t('homepage-partners-section-title')}</h1>
            <HomepagePartnersCarousel />
        </div>
    )
}

export default HomepagePartners