import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomepageServicesCard.module.css';
import { useNavigate } from 'react-router-dom';


function HomepageServicesCard(service) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onCardClicked = (link) => {
        navigate(`${link}`);
    }
    return (
        <div className={styles.homepage_service_card_container} onClick={() => { onCardClicked(service.link) }}>
            <div className={styles.homepage_service_card_img}>
                <img src={service.image} alt={service.title} />
            </div>
            <div className={styles.homepage_service_card_title}>
                <h3>{t(`${service.title}`)}</h3>
            </div>
            <div className={styles.homepage_service_card_body}>
                <p>{t(`${service.description}`)}</p>
            </div>
        </div>

    );
}

export default HomepageServicesCard