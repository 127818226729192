import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProcedureImageLeft.module.css';
import { motion } from "framer-motion";

const logoVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 150,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
        }
    }
};
function ProcedureImageLeft(procedure) {
    const { t } = useTranslation();
    const procedureToShow = procedure.procedure;
    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            <motion.div variants={logoVariants}>
                <div className={styles.procedure_image_left_container}>
                    <img src={procedureToShow.image} alt={procedureToShow.title} />
                    <div className={styles.procedure_image_left_body}>
                        <h2>{t(`${procedureToShow.title}`)}</h2>
                        <p>{t(`${procedureToShow.description}`)}</p>
                    </div>
                </div>
            </motion.div>
        </motion.div>

    )
}

export default ProcedureImageLeft