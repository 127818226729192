import React from 'react';
import styles from './HomepagePartnersCard.module.css';

function HomepagePartnersCard(partner) {
    const partnerToBeDisplayed = partner.partner;
    return (
        <div className={styles.homepage_partners_card}>
            <div className={styles.homepage_partners_card_image} >
                <img src={partnerToBeDisplayed.image} alt={partnerToBeDisplayed.title} />
            </div>
            <div className={styles.homepage_partners_card_body}>
                <h3>{partnerToBeDisplayed.title}</h3>
            </div>
        </div>
    )
}

export default HomepagePartnersCard