import React from 'react';
import QRCode from '../../../../assets/qr-code.png';
import Time from '../../../../assets/time.png';
import EasyUse from '../../../../assets/easy-use.png';
import Translation from '../../../../assets/translation.png';
import Design from '../../../../assets/final-design.png';
import Safety from '../../../../assets/safety.png';
import styles from './QRPackage.module.css';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";

const logoVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 150,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
        }
    }
};

const packages = [
    {
        title: "qr-code-menus-page-package-contactless-service-title",
        description: "qr-code-menus-page-package-contactless-service-paragraph",
        image: QRCode
    },
    {
        title: "qr-code-menus-page-package-save-time-title",
        description: "qr-code-menus-page-package-save-time-paragraph",
        image: Time
    },
    {
        title: "qr-code-menus-page-package-easy-to-use-title",
        description: "qr-code-menus-page-package-easy-to-use-paragraph",
        image: EasyUse
    },
    {
        title: "qr-code-menus-page-package-multiple-languages-title",
        description: "qr-code-menus-page-package-multiple-languages-paragraph",
        image: Translation
    },
    {
        title: "qr-code-menus-page-package-attractive-design-title",
        description: "qr-code-menus-page-package-attractive-design-paragraph",
        image: Design
    },
    {
        title: "qr-code-menus-page-package-safety-title",
        description: "qr-code-menus-page-package-safety-paragraph",
        image: Safety
    }
];

function QRPackage() {
    const { t } = useTranslation();
    return (
        <div className={styles.qr_package_container}>
            <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
            >
                <motion.div variants={logoVariants}>
                    <div className={styles.qr_package_container_row}>
                        {
                            packages.slice(0, 3).map((qr_package, index) => {
                                return <div className={styles.qr_package_single_package} key={index}>
                                    <img src={qr_package.image} alt={qr_package.title} />
                                    <h1>{t(`${qr_package.title}`)}</h1>
                                    <p>{t(`${qr_package.description}`)}</p>
                                </div>
                            })
                        }
                    </div>
                </motion.div>
            </motion.div>

            <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
            >
                <motion.div variants={logoVariants}>
                    <div className={styles.qr_package_container_row}>
                        {
                            packages.slice(3, 6).map((qr_package, index) => {
                                return <div className={styles.qr_package_single_package} key={index}>
                                    <img src={qr_package.image} alt={qr_package.title} />
                                    <h1>{t(`${qr_package.title}`)}</h1>
                                    <p>{t(`${qr_package.description}`)}</p>
                                </div>
                            })
                        }
                    </div>
                </motion.div>
            </motion.div>

        </div>
    )
}

export default QRPackage