import React from 'react';
import { useTranslation } from 'react-i18next';
import Shopping from '../../../../assets/shopping.png';
import styles from './EcommerceHeader.module.css';

function EcommerceHeader() {
    const { t } = useTranslation();
    return (
        <div className={styles.ecommerce_header_container}>
            <h1>{t('ecommerce-page-header-title')}</h1>
            <h3>{t('ecommerce-page-header-subtitle01')}</h3>
            <div className={styles.ecommerce_header_body}>
                <div className={styles.ecommerce_header_body_text}>
                    <h2>{t('ecommerce-page-header-subtitle02')}</h2>
                    <p>{t('ecommerce-page-header-paragraph01')}</p>
                    <p>{t('ecommerce-page-header-paragraph02')}</p>
                </div>
                <img src={Shopping} alt='Ecommerce Development' />
            </div>
        </div>
    )
}

export default EcommerceHeader