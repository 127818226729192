import React from 'react'
import 'antd/dist/antd.css';
import { Menu } from 'antd';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend'; import { Link, useLocation, useNavigate } from 'react-router-dom';
import LiF_textLogo from '../../assets/LiF_text.png';
import styles from './CustomNavbar.module.css';
import { GlobalOutlined } from '@ant-design/icons';
import 'flag-icon-css/css/flag-icons.min.css'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'el'],
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

        fallbackLng: "en",
        detection: {
            // order and from where user language should be detected
            order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],

        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        }
    });


const languages = [
    {
        lang_code: 'en',
        name: 'English',
        country_code: 'gb'
    },
    {
        lang_code: 'el',
        name: 'Ελληνικά',
        country_code: 'gr'
    }
]

function CustomNavbar() {
    const location = useLocation();
    const { pathname } = location;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onServicesClicked = () => {
        navigate('/services');
    }

    return (
        <Menu mode="horizontal" selectedKeys={[pathname]} className={styles.customNavbar}>
            <Menu.Item key="logo" className={styles.menu_logo}>
                <img src={LiF_textLogo} alt='Logic in Flow Logo' />
            </Menu.Item>
            <Menu.Item key="/">
                <Link to='/'>
                    {t('navbar-home')}
                </Link>
            </Menu.Item>
            <Menu.SubMenu key="/services" title={t('navbar-services')} onTitleClick={onServicesClicked}>
                <Menu.Item key="/web-development">
                    <Link to='/web-development'>
                        {t('navbar-services-web-development')}
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ecommerce-development">
                    <Link to='/ecommerce-development'>
                        {t('navbar-services-ecommerce-development')}
                    </Link>
                </Menu.Item>
                <Menu.Item key="/mobile-app-development">
                    <Link to='/mobile-app-development'>
                        {t('navbar-services-app-development')}
                    </Link>
                </Menu.Item>
                <Menu.Item key="/qr-code-menus">
                    <Link to='/qr-code-menus'>
                        {t('navbar-qr-code-menus')}
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="/about-us">
                <Link to='/about-us'>
                    {t('navbar-about-us')}
                </Link>
            </Menu.Item>
            <Menu.Item key="/contact">
                <Link to='/contact'>
                    {t('navbar-contact-us')}
                </Link>
            </Menu.Item>
            <Menu.SubMenu key="/languages" icon={<GlobalOutlined />}>
                {
                    languages.map(({ lang_code, name, country_code }) => (
                        <Menu.Item key={country_code} onClick={() => i18n.changeLanguage(lang_code)}>
                            <span className={`flag-icon flag-icon-${country_code} mx-2`}>
                            </span>
                            &nbsp;
                            {name}
                        </Menu.Item>
                    ))
                }

            </Menu.SubMenu>
        </Menu>
    )
}

export default CustomNavbar