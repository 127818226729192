import React from 'react'
import Facebook from '../../assets/SocialMedia/facebook.png';
import Instagram from '../../assets/SocialMedia/instagram.png';
import LinkedIn from '../../assets/SocialMedia/linkedin.png';
import Twitter from '../../assets/SocialMedia/twitter.png';

import styles from './CustomFooter.module.css';

function CustomFooter() {
    return (
        <div className={styles.footer_container}>
            <div className={styles.footer_social_row}>
                <div className={styles.footer_social_col}>
                    <a href="https://www.facebook.com/logicinflow" target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="facebook-logo" />
                    </a>
                </div>
                <div className={styles.footer_social_col}>
                    <a href="https://www.instagram.com/logicinflow/" target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="instagram-logo" />
                    </a>
                </div>
                <div className={styles.footer_social_col}>
                    <a href="https://www.linkedin.com/company/logic-in-flow" target="_blank" rel="noreferrer">
                        <img src={LinkedIn} alt="linkedin-logo" />
                    </a>
                </div>
                <div className={styles.footer_social_col}>
                    <a href="https://twitter.com/LogicInFlow_" target="_blank" rel="noreferrer">
                        <img src={Twitter} alt="twitter-logo" />
                    </a>
                </div>
            </div>
            <p class="copyright">Logic in Flow © {new Date().getFullYear()}</p>
        </div>
    )
}

export default CustomFooter