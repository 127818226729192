import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from '../../../../assets/qr-code.webp';
import styles from './QRCodesHeader.module.css';

function QRCodesHeader() {
    const { t } = useTranslation();
    return (
        <div className={styles.qr_codes_header}>
            <h1>{t('qr-code-menus-page-header-title')}</h1>
            <h3>{t('qr-code-menus-page-header-subtitle')}</h3>
            <div className={styles.qr_codes_header_row}>
                <div className={styles.qr_codes_header_row_body}>
                    <p>{t('qr-code-menus-page-header-paragraph01')}</p>
                    <p>{t('qr-code-menus-page-header-paragraph02')}</p>
                    <p>{t('qr-code-menus-page-header-paragraph03')}</p>
                </div>
                <img src={QRCode} alt='QR Code' />
            </div>
        </div>
    )
}

export default QRCodesHeader