import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ScrollToTop from '../utils/ScrollToTop';
import { AboutUs, Contact, Ecommerce, Homepage, MobileApps, Page404, QRCodes, Services, WebDevelopment } from '../Pages';


class Routing extends Component {
    state = {}
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/web-development" element={<WebDevelopment />} />
                    <Route path="/ecommerce-development" element={<Ecommerce />} />
                    <Route path="/mobile-app-development" element={<MobileApps />} />
                    <Route path="/qr-code-menus" element={<QRCodes />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Routing
