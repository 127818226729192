import MAK from '../assets/Partners/mak-architects-logo.png';
import MSL from '../assets/Partners/msl-logo.png';
import TartaraRecords from '../assets/Partners/tartara-records.jpg';
import TidyAtSea from '../assets/Partners/tidyatsea.png';

import Fast from '../assets/Values/fast-delivery.png';
import Secure from '../assets/Values/secure.png';
import Stable from '../assets/Values/stable-network.png';

import WebDevelopment from '../assets/programming-code-signs.png';
import Ecommerce from '../assets/shopping-cart.png';
import AppDevelopment from '../assets/mobile-app.png';
import QRCode from '../assets/qr-code.png';

export const partners = [
    {
        title: "MAK Architects & Engineers",
        image: MAK,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisi nulla, pellentesque id commodo non, convallis non ante. Aenean luctus congue hendrerit.",
        link: "https://makarchitectsengineers.com"
    },
    {
        title: "Management Science Laboratory - Athens University of Economic & Business",
        image: MSL,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisi nulla, pellentesque id commodo non, convallis non ante. Aenean luctus congue hendrerit.",
        link: "https://aueb-msl.gr"
    },
    {
        title: "Tartara Records",
        image: TartaraRecords,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisi nulla, pellentesque id commodo non, convallis non ante. Aenean luctus congue hendrerit.",
        link: "https://www.tartara-records.com"
    },
    {
        title: "Tidy At Sea",
        image: TidyAtSea,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisi nulla, pellentesque id commodo non, convallis non ante. Aenean luctus congue hendrerit.",
        link: "https://www.tidyatsea.com"
    }
];



export const values = [
    {
        title: "homepage-values-fast-title",
        image: Fast,
        body: "homepage-values-fast-text"
    },
    {
        title: "homepage-values-secure-title",
        image: Secure,
        body: "homepage-values-secure-text"
    },
    {
        title: "homepage-values-stable-title",
        image: Stable,
        body: "homepage-values-stable-text"
    }
];

export const services = [
    {
        title: "homepage-services-section-web-development-title",
        description: "homepage-services-section-web-development-text",
        image: WebDevelopment,
        link: "/web-development"
    },
    {
        title: "homepage-services-section-ecommerce-title",
        description: "homepage-services-section-ecommerce-text",
        image: Ecommerce,
        link: "/ecommerce-development"
    },
    {
        title: "homepage-services-section-mobile-app-title",
        description: "homepage-services-section-mobile-app-text",
        image: AppDevelopment,
        link: "/mobile-app-development"
    },
    {
        title: "homepage-services-section-qr-code-menus-title",
        description: "homepage-services-section-qr-code-menus-text",
        image: QRCode,
        link: "/qr-code-menus"
    }
];