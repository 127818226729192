import React from 'react';
import { CustomFooter, CustomNavbar } from '../../Components';
import WebPackage from '../OurServices/WebPackage/WebPackage';
import WebDevelopmentHeader from './WebDevelopmentHeader/WebDevelopmentHeader';

function WebDevelopment() {
    return (
        <div>
            <CustomNavbar />
            <WebDevelopmentHeader />
            <WebPackage />
            <CustomFooter />
        </div>
    )
}

export default WebDevelopment