import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Loading.css';

// bootstrap Spinner for loading
function Loading() {
    return <div className='spinner-container'>
        <Spinner animation="border" className='spinner'/>
    </div>;
}

export default Loading;

