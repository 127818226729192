import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WebDevImg from '../../../assets/web-development.png';
import styles from './WebDevelopmentHeader.module.css';

function WebDevelopmentHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function goToContact(){
        navigate('/contact');
    }

    return (
        <div className={styles.web_development_header_container}>
            <h1>{t('web-development-page-header-title')}</h1>
            <h3>{t('web-development-page-header-subtitle')}</h3>
            <div className={styles.web_development_header_body}>
                <div className={styles.web_development_header_body_paragraphs}>
                    <p>{t('web-development-page-header-paragraph01')}</p>
                    <p>{t('web-development-page-header-paragraph02')}</p>
                    <p>{t('web-development-page-header-paragraph03')}</p>
                    <button className='main-btn' onClick={goToContact}>{t('contact-us-btn')}</button>
                </div>
                <img src={WebDevImg} alt='Web Development' />
            </div>
            <div className={styles.web_development_header_body_paragraphs_bottom}>
                <p>
                    {t('web-development-page-header-paragraph04')}
                </p>
                <p>
                    {t('web-development-page-header-paragraph05')}
                </p>
            </div>

        </div>
    )
}

export default WebDevelopmentHeader