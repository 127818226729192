import React from 'react';
import { useTranslation } from 'react-i18next';
import HomepageValues from '../../../Homepage/HomepageValues/HomepageValues';
import styles from './UserCentered.module.css';

function UserCentered() {
    const { t } = useTranslation();

    return (
        <div className={styles.user_centered_container}>
            <h1>{t('mobile-apps-page-user-centered-title')}</h1>
            <p>
                {t('mobile-apps-page-user-centered-paragraph')}
            </p>
            <HomepageValues />
        </div>
    )
}

export default UserCentered