import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './ServicesCard.module.css';
import { motion } from "framer-motion";


const logoVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 150,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
        }
    }
};


function ServicesCard(service) {
    const navigate = useNavigate();

    function goTo(path) {
        navigate(path);
    }

    const { t } = useTranslation();
    const serviceToShow = service.service;
    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            <div />
            <motion.div variants={logoVariants}>
                <div className={styles.services_card_container}>
                    <div className={styles.services_card_img_container}>
                        <img src={serviceToShow.image} alt={serviceToShow.title} />
                    </div>
                    <div className={styles.services_card_body}>
                        <h1>{t(`${serviceToShow.title}`)}</h1>
                        <p>{t(`${serviceToShow.description}`)}</p>
                        <button className='main-btn' onClick={() => {
                            goTo(serviceToShow.link);
                        }}>{t('learn-more-btn')}</button>
                    </div>
                </div>
            </motion.div>
        </motion.div>

    )
}

export default ServicesCard