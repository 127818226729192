import React from 'react';
import { CustomFooter, CustomNavbar } from '../../../Components';
import AfterRelease from './AfterRelease/AfterRelease';
import MobileAppsHeader from './MobileAppsHeader/MobileAppsHeader';
import Procedure from './Procedure/Procedure';
import Quality from './Quality/Quality';
import UserCentered from './UserCentered/UserCentered';

function MobileApps() {
    return (
        <div>
            <CustomNavbar />
            <MobileAppsHeader />
            <UserCentered />
            <Quality />
            <Procedure />
            <AfterRelease />
            <CustomFooter />
        </div>
    )
}

export default MobileApps