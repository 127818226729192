import React from 'react';
import { CustomFooter, CustomNavbar } from '../../../Components';
import WebPackage from '../WebPackage/WebPackage';
import EcommerceHeader from './EcommerceHeader/EcommerceHeader';
import YourChoice from './YourChoice/YourChoice';

function Ecommerce() {
    return (
        <div>
            <CustomNavbar />
            <EcommerceHeader />
            <YourChoice />
            <WebPackage />
            <CustomFooter />
        </div>
    )
}

export default Ecommerce