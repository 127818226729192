import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SmartPhone from '../../../../assets/smartphone.png'
import styles from './MobileAppsHeader.module.css';

function MobileAppsHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function goToContact(){
        navigate('/contact');
    }
    return (
        <div className={styles.mobile_apps_header_container}>
            <h1>{t('mobile-apps-page-header-title')}</h1>
            <h3>{t('mobile-apps-page-header-subtitle')}</h3>
            <div className={styles.mobile_apps_header_row}>
                <div className={styles.mobile_apps_header_body}>
                    <p>{t('mobile-apps-page-header-paragraph01')}</p>
                    <p>{t('mobile-apps-page-header-paragraph02')}</p>
                    <p>{t('mobile-apps-page-header-paragraph03')}</p>
                    <button className='main-btn' onClick={goToContact}>{t('contact-us-btn')}</button>
                </div>
                <div className={styles.mobile_apps_header_img}>
                    <img src={SmartPhone} alt='Mobile App Development' />
                </div>
            </div>
        </div>
    )
}

export default MobileAppsHeader