import React from 'react';
import Responsive from '../../../assets/WebPackage/responsive.png'
import SEO from '../../../assets/WebPackage/seo.png'
import Social from '../../../assets/WebPackage/social-media.png'
import UI from '../../../assets/WebPackage/ui.png'
import Camera from '../../../assets/WebPackage/camera.png'
import Speed from '../../../assets/WebPackage/speedometer.png'
import styles from './WebPackage.module.css';
import { t } from 'i18next';

const packages = [
    {
        title: "web-package-responsive-design-title",
        description: "web-package-responsive-design-text",
        image: Responsive
    },
    {
        title: "web-package-seo-title",
        description: "web-package-seo-text",
        image: SEO
    },
    {
        title: "web-package-social-media-title",
        description: "web-package-social-media-text",
        image: Social
    },
    {
        title: "web-package-ui-design-title",
        description: "web-package-ui-design-text",
        image: UI
    },
    {
        title: "web-package-speed-title",
        description: "web-package-speed-text",
        image: Camera
    },
    {
        title: "web-package-product-photography-title",
        description: "web-package-product-photography-text",
        image: Speed
    }
]

function WebPackage() {
  return (
    <div className={styles.web_package_container}>
        <div className={styles.web_package_row}>
            {
                packages.slice(0,3).map((tempPackage) => {
                    return <div className={styles.web_package_card}>
                        <div className={styles.web_package_card_img}>
                            <img src={tempPackage.image} alt={tempPackage.title} />
                        </div>
                        <h3>{t(`${tempPackage.title}`)}</h3>
                        <p>{t(`${tempPackage.description}`)}</p>
                    </div>
                })
            }
        </div>
        <div className={styles.web_package_row}>
            {
                packages.slice(3,6).map((tempPackage) => {
                    return <div className={styles.web_package_card}>
                        <div className={styles.web_package_card_img}>
                            <img src={tempPackage.image} alt={tempPackage.title} />
                        </div>
                        <h3>{t(`${tempPackage.title}`)}</h3>
                        <p>{t(`${tempPackage.description}`)}</p>
                    </div>
                })
            }
        </div>
    </div>
  )
}

export default WebPackage