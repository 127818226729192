import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import LiF_logo from '../../../assets/LiF.png';
import styles from './HomepageHeader.module.css';
import { motion } from "framer-motion";


const logoVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 150,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
        }
    }
};


function HomepageHeader() {
    const { t } = useTranslation();

    return (
        <div className={styles.homepage_header_container}>
            <div className={styles.homepage_header_content}>
                <h1>Logic in Flow</h1>
                <p>
                    {t('homepage-header-subtitle')}
                    <br />{t('homepage-header-text')}
                </p>
                <Link to="/services">
                    <button type="button" className='main-btn'>{t('homepage-header-btn')}</button>
                </Link>
            </div>
            <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
            >
                <div />
                <motion.div variants={logoVariants}>
                    <div className={styles.homepage_header_image}>
                        <img src={LiF_logo} alt="logo" />
                    </div>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default HomepageHeader