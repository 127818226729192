import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QualityGuarantee from '../../../../assets/guarantee.png';
import styles from './Quality.module.css';

function Quality() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function goToContact(){
        navigate('/contact');
    }
    return (
        <div className={styles.quality_container}>
            <div className={styles.quality_container_img}>
                <img src={QualityGuarantee} alt='Guaranteed Quality' />
            </div>
            <div className={styles.quality_container_body}>
                <h1>{t('mobile-apps-page-quality-title')}</h1>
                <p>
                    {t('mobile-apps-page-quality-paragraph')}
                </p>
                <button className='main-btn' onClick={goToContact}>{t('contact-us-btn')}</button>
            </div>
        </div>
    )
}

export default Quality