import React from 'react';
import { CustomNavbar, CustomFooter } from '../../Components';
import styles from './AboutUs.module.css';
import AboutUsHeader from './AboutUsHeader/AboutUsHeader';

function AboutUs() {
    return (
        <div className={styles.about_us_container}>
            <CustomNavbar />
            <AboutUsHeader />
            <CustomFooter />
        </div>
    )
}

export default AboutUs