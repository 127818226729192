import React from 'react'
import { CustomFooter, CustomNavbar } from '../../../Components'
import QRCodesHeader from './QRCodesHeader/QRCodesHeader'
import QRPackage from './QRPackage/QRPackage'

function QRCodes() {
    return (
        <div>
            <CustomNavbar />
            <QRCodesHeader />
            <QRPackage />
            <CustomFooter />
        </div>
    )
}

export default QRCodes