import React from 'react';
import { CustomFooter, CustomNavbar } from '../../Components';
import { services } from '../../utils/constants';
import styles from './Services.module.css';
import ServicesCard from './ServicesCard/ServicesCard';

function Services() {
    return (
        <div>
            <CustomNavbar />
            <div className={styles.services_container}>
                {
                    services.map((service, index) => {
                        return <ServicesCard service={service} key={index} />
                    })
                }
            </div>
            <CustomFooter />
        </div>
    )
}

export default Services